.about {
    display: flex;
    padding: 150px;
    gap: 100px;
    align-items: center;
}

.girl {
    width: 400px;
    height: auto;
    border-radius: 40px;
}

.title {
    font-size: 60px;
    font-weight: 800;
    margin: 0 0 10px 0;
}

.about .find {
    margin-bottom: 0;
}

.about ul {
    margin-top: 0;
    list-style-type: none;
    margin-left: -40px;
}

.about p, li {
    font-size: 20px;
    line-height: 30px;
}

.about-img {
    display: flex;
    align-items: center;
}

.about a {
    color: #36687d;
}

.about a:hover {
    color: #4c96b6;
}

.about-text p, li {
    line-height: 1.6;
}