.github {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
}

.github img {
    width: 50px;
    transition: 0.3s ease;
}

.github img:hover {
    transform: scale(1.1);
}

.projects {
    padding: 50px 150px 150px 150px;
}

.projects .skills {
    margin-bottom: 15px;
}

.more-projects {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.more-projects button {
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 12px;
    border: 1.5px solid #36687d;
    background: none;
    color: #36687d;
}

.more-projects button:hover {
    color: #fffbf5;
    background-color: #36687d;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 4rem;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 6rem;
  }

@media (max-width: 30.625em) {
    .projects-section {
      padding: 6rem 1rem;
    }
  
    .projects-grid {
      grid-template-columns: 1fr;
    }
}
  
