html {
    scroll-behavior: smooth; 
}

* {
    font-family: "Open Sans", sans-serif;
}

body {
    background-color: #fffbf5;
    color: #2a2a2a;
}

footer {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

/* media queries */
@media only screen and (max-width: 1024px) {
    .about {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .experience-header, .github {
        display: flex;
        flex-direction: column;
    }
    .buttons {
        margin-bottom: 15px;
    }
    .github img {
        margin-bottom: 25px;
    }
    .title, .title-work {
        font-size: 50px;
    }
    .hero h1 {
        font-size: 60px;
    }
}

@media only screen and (max-width: 600px) {
    .title, .title-work {
        font-size: 40px;
        text-align: center;
    }

    .experience-header, .github {
        display: flex;
        flex-direction: column;
    }

    /* HERO */
    .hero img {
        width: 40px;
    }

    .hero h1 {
        font-size: 40px;
    }

    .hero p {
        font-size: 16px;
        max-width: 300px;
    }

    .hero .main-content {
        top: 20%;
    }

    /* ABOUT */
    .girl {
        width: 280px;
    }

    .about p, li {
        font-size: 16px;
    }

    /* EXPERIENCE */
    .buttons button {
        width: 120px;
        font-size: 14px;
    }

    .buttons {
        margin-bottom: 15px;
    }

    .company-logo {
        height: 50px;
        width: 50px;
    }

    .company, .project-title {
        font-size: 18px;
    }

    .collapsible .work-toggle {
        width: 15px;
        height: 15px;
    }

    .date {
        font-size: 14px;
    }

    .position {
        margin-top: 5px;
        font-size: 16px;
    }

    .collapsible-content ul li {
        font-size: 14px;
        margin-left: -25px;
    }

    .skill {
        font-size: 14px;
    }

    /* PROJECTS */
    .github img {
        width: 40px;
        margin-bottom: 25px;
    }

    .project-desc {
        font-size: 14px;
    }

    .project-collapsible .project-desc {
        text-align: center;
    }

    .more-projects button {
        font-size: 16px;
    }

    .all-projects .intro-desc {
        font-size: 14px;
    }

    .all-projects .project-name {
        font-size: 16px;
        text-align: center;
    }

    .links {
        font-size: 14px;
    }

    .intro .title-work {
        font-size: 30px !important;
    }

    .projects-title {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .project-collapsible .skills {
        text-align: center;
    }

    .project-collapsible {
        margin: 20px 0px;
    }

    .hero, .about, .work, .projects, .all-projects {
        padding: 50px;
    }

    .navbar {
        margin: 0;
    }

    .overlay-content a {
        font-size: 25px;
    }

    .line {
        height: 2px !important;
        width: 25px !important;
    }

    footer {
        font-size: 14px;
    }
}