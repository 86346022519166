.overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.overlay-content a {
    text-decoration: none;
    animation: fadeIn 1.5s;
    color: #2a2a2a;
    font-size: 36px;
    font-weight: 700;
    z-index: 5;
}

.overlay-content a:hover {
    color: #36687d;
}

.link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#36687d, #36687d);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .5s ease-in-out;
}
.link-underline:hover {
    background-size: 100% 3px;
    background-position: 0 100%;
    color: #ffffffc6;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 100%; }
}