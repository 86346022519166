.work-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.collapsible {
    color: #2a2a2a;
    cursor: pointer;
    text-align: left;
    outline: none;
    align-items: center;
}

.work-header {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 5px 0px 0px 0px;
}

.collapsible .work-toggle {
    height: 20px;
    width: 20px;
}

.skills {
    gap: 10px;
}

.company {
    font-size: 30px;
}

.company-logo {
    width: 60px;
}

.skill {
    background-color: #7f98a3;
    padding: 5px 15px;
    font-size: 18px;
    border-radius: 10px;
    color: #fffbf5;
    width: fit-content;
    display: inline-block;
    margin: 1px 5px 10px 0;
}