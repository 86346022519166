.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 100px;
    gap: 20px;
    position: relative;
    height: calc(100vh - 200px);
}

.hero .main-content {
    position: absolute;
    top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.two {
    animation: fadeInAnimation ease 0.5s;
    text-align: center;
}

.three {
    animation: fadeInAnimation ease 1s;
    text-align: center;
    font-weight: 800;
    font-size: 80px;
    margin: 0;
}

.desc {
    animation: fadeInAnimation ease 1.5s;
    text-align: center;
    max-width: 500px;
    margin-bottom: 30px;
}

.socials {
    animation: fadeInAnimation ease 2s;
    display: flex;
    gap: 40px;
}

.socials a:hover {
    transform: scale(1.1);
    transition: 0.3s ease;
}

.hero p {
    font-size: 20px;
}

.arrow-down {
    width:60px;
    height:40px;
    margin:0 0 0 -30px;
    position:absolute;
    left: 50%;
    bottom:0px;
    animation: arrow 0.5s 1s infinite ease-out alternate;
    
  }
  
.arrow-down:hover{
    animation-play-state: paused;
}
  
.left{
    position:absolute;
    height:10px;
    width:40px;
    background:#36687d;
    transform:rotate(240deg);
    top:10px;
    left:25px;
    border-radius:4px;
    transform-origin:5px 50%;
    animation: leftArrow 0.5s 1s infinite ease-out alternate;
}
  
.right{
    position:absolute;
    height:10px;
    width:40px;
    background:#36687d;
    transform:rotate(-60deg);
    top:10px;
    left:25px;
    border-radius:4px;
    transform-origin:5px 50%;
    animation: rightArrow 0.5s 1s infinite ease-out alternate;
}
  
@keyframes arrow{
    0% {
      bottom:0px;
    }
    100%{
      bottom:40px;
    }
}

@keyframes leftArrow{
    0% {
    }
    100%{
      transform:rotate(225deg);
    }
}
  
@keyframes rightArrow{
    0% {
    }
    100%{
      transform:rotate(-45deg);
    }
}