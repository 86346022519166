.position {
    font-weight: 600;
    margin: 0;
}

.date {
    margin: 10px 0px 20px 0px;
    font-size: 18px;
    font-weight: 600;
    color: #7f98a3;
}

.collapsible-container {
    display: flex;
    align-items: stretch;
    gap: 20px;
    margin-left: 15px;
    margin-bottom: 20px;
    transition: 0.3s ease;
}

.graphics {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
}

.content-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 8px;
    margin-bottom: 4px;
    background-color: #7f98a3;
}

.content-line {
    width: 2px;
    flex-grow: 1;
    background-color: #7f98a3;
}