.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 30px 0px 30px;
    position: sticky;
    top: 15px;
    z-index: 4;
    height: 50px;
}

.logo-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
}

.logo-nav p {
    font-size: 32px;
    font-weight: 700;
    color: #2a2a2a;
    margin: 0;
}

.logo-nav a {
    text-decoration: none;
}